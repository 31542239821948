import { getAuth } from "firebase/auth";
import AuthApp from "./AuthApp";
import { AuthProvider, useFirebaseApp } from "reactfire";
import "./App.css";

function App() {
  const app = useFirebaseApp();
  const auth = getAuth(app);

  return (
    <AuthProvider sdk={auth}>
      <AuthApp />
    </AuthProvider>
  );
}

export default App;
