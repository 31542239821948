import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection, query, where, orderBy } from "firebase/firestore";

function useQueryImages(uid) {
  const firestore = useFirestore();
  const imagesCollection = 'images';
  const collectionRef = collection(firestore, imagesCollection);
  const constraint1 = where("uid", "==", uid);
  const constraint2 = where("status", "==", "FINISHED");
  const imagesQuery = query(collectionRef, constraint1, constraint2, orderBy("finished_at", "desc"));

  return useFirestoreCollectionData(imagesQuery, { idField: "id" });
}

export default useQueryImages;