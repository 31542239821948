import { useCallback } from 'react';
import { useFirestore } from 'reactfire';
import { doc, updateDoc } from 'firebase/firestore';

function useUpdateImage(imageId) {
  const firestore = useFirestore();
  const imagesCollection = 'images';

  const docRef = doc(firestore, imagesCollection, imageId);

  return useCallback(
    (update) => {
      return updateDoc(docRef, update);
    },
    [docRef]
  );
}

export default useUpdateImage;