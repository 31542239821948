// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useAuth } from "reactfire";
import './Login.css'

const Login = () => {
    const authInstance = useAuth();
  // convert the v9 auth instance into a v8 auth instance
  const v8AppInstance = firebase.initializeApp(authInstance.app.options);
  const v8AuthInstance = v8AppInstance.auth();
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/generate',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            disableSignUp: {
                status: true
            }
        } 
        ],
    };
    return (
        <div className="Login-main">
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={v8AuthInstance} />
        </div>
      );
}

export default Login;