import React from "react";
import { useParams } from "react-router-dom";
import { useDocumentName } from "./context/DocumentContext";
import Image from "./Image";
import Details from "./Details";
import useImageDoc from "./hooks/use-image-doc";
import "./AuthImage.css";

const AuthImage = ({ uid }) => {

    const { setDocumentName } = useDocumentName();
    const { imageId } = useParams();

    const gsUrl = `gs://leoai-d038e.appspot.com/${uid}/images/${imageId}_original.png`
    const docId = `${uid}_${imageId}`;
    const { status, data: imageDoc } = useImageDoc(docId);
    const loading = status === "loading";

    if (loading) {
        return <div>Loading...</div>;
    }

    const prompt = imageDoc && "prompt" in imageDoc ? imageDoc.prompt : "";
    const imageName = imageDoc?.name ? imageDoc.name : "";
    setDocumentName(imageName);

    return (
        <div className="AuthImage-main">
            <div className="AuthImage-empty-column" />
            <div className="AuthImage-image">
                {gsUrl ? <Image url={gsUrl} prompt={prompt} /> : <div>Not found</div>}
            </div>

            <div className="AuthImag-info-column">
                <Details
                    name={"My photo"}
                    prompt={prompt}
                    timestamp="now"
                    doc={imageDoc}
                />
            </div>
        </div>
    );
};

export default AuthImage;
