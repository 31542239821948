import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/joy/Button";
import Textarea from "@mui/joy/Textarea";
import { useUser } from "reactfire";
import "./App.css";
import Processing from "./Processing";
import Robot from "./Robot2";
import "./prompt.css";

const PROMPT_WAITING = "WAITING";
const PROMPT_PROCESSING = "PROCESSING";
const PROMPT_ERROR = "ERROR";

const Prompt = () => {
  const { status, data: user } = useUser();
  const [promptStatus, setPromptStatus] = useState(PROMPT_WAITING);
  const navigate = useNavigate(); // Initialize useHistory
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const textareaContent = formData.get("prompt_area");

    if (textareaContent === ""){
      console.log('Empty prompt')
      return
    }
    if (!user) {
      console.log("No user is signed in.");
      return;
    }

    try {
      const token = await user.getIdToken();
      setPromptStatus(PROMPT_PROCESSING);
      let apiEndpoint;
      if (process.env.NODE_ENV !== "production") {
        apiEndpoint =
        "http://127.0.0.1:5001/leoai-d038e/us-central1/on_request_example/inference";
      } else {
        apiEndpoint = "https://on-request-example-niap44752a-uc.a.run.app/inference"
      }

      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          prompt: textareaContent,
        }),
      });
      if (response.status === 201) {
        const responseData = await response.json();
        console.log(responseData);
        // Redirect to new path with ID from response data
        navigate(`/images/${responseData["id"]}`);
      } else {
        // Handle other statuses or errors
        console.error("Error with status:", response.status);
        setPromptStatus(PROMPT_ERROR);
      }

    } catch (error) {
      console.error("Error:", error);
      // handle errors
    }
  };

  if (promptStatus === PROMPT_ERROR) {
    return (
      <div className="Prompt-main">
        Something went wrong.
        <div className="Prompt-retry" onClick={() => setPromptStatus(PROMPT_WAITING)}>Retry.</div>
      </div>
    )
  }
  return (
    <div className="Prompt-main">
      {promptStatus === PROMPT_WAITING ? (
        <div className="Prompt-components">
          <div className="Prompt-logo">
            <Robot />
          </div>
            <form className="Prompt-form" onSubmit={handleSubmit}>
              <div className="Prompt-text">
                <Textarea
                  minRows={5}
                  maxRows={5}
                  name="prompt_area"
                  placeholder="Input your vision ..."
                  sx={{
                    borderBottom: '2px solid',
                    borderColor: 'var(--textarea-border)',
                    borderRadius: 0,
                    backgroundColor: "var(--textarea-background)",
                    color: "var(--textarea-text)",
                    '&:hover': {
                      borderColor: 'var(--textarea-border)',
                    },
                    '&::before': {
                      border: '1px solid var(--button-color)',
                      transform: 'scaleX(0)',
                      left: 0,
                      right: 0,
                      bottom: '-2px',
                      top: 'unset',
                      transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                      borderRadius: 0,
                    },
                    '&:focus-within::before': {
                      transform: 'scaleX(1)',
                    },
                  }}
                />
              </div>

              <Button type="submit"
                sx={{
                  "background-color": "var(--button-color)",
                  "&:hover": {
                    "background-color": "var(--button-hover)"
                  }
                }}>
                Generate
              </Button>
            </form>
        </div>
      ) : (
        <Processing />
      )}
    </div>
  );
};

export default Prompt;
