import React from "react";
import { Link } from "react-router-dom";
import useQueryImages from './hooks/use-query-images';
import Thumbnail from "./Thumbnail";
import "./Gallery.css";

const Gallery = ({ uid }) => {

  const { status, data: images } 
    = useQueryImages(uid);

  if (status === "success") {
    return (
      <div className="Gallery-main">
        {images.map((img) => {
          const url = `gs://leoai-d038e.appspot.com/${img.uid}/images/${img.image_id}_300x300.png`;
          return (
            <div className="Images-thumbnail">
              <Link to={`/images/${img.image_id}`}>
                <Thumbnail url={url} />
              </Link>
            </div>
          );
        })}
      </div>
    );
  }

  return <div className="Image-main">Images</div>;
};

export default Gallery;
