import { Routes, Route, Navigate } from "react-router-dom";
import React, { useState } from "react";
import { getStorage } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {
  connectStorageEmulator
} from "firebase/storage";
import { useUser } from "reactfire";
import Prompt from "./Prompt";
import Login from "./Login";
import AuthImage from "./AuthImage";
import Gallery from "./Gallery";
import Header from "./Header";
import { DocumentNameProvider } from "./context/DocumentContext";
import "./App.css";

import {
  FirestoreProvider,
  StorageProvider, useFirebaseApp
} from "reactfire";

function AuthApp() {
  const app = useFirebaseApp();
  const storage = getStorage(app);
  const firestore = getFirestore(app);

  const { status, data: user } = useUser();
  const [isLightTheme, setIsLightTheme] = useState(true);

  const updateTheme = (isLightTheme) => {
    setIsLightTheme(isLightTheme)
  }

  if (process.env.NODE_ENV !== "production" && window.location.hostname === "localhost" && !firestore._settingsFrozen) {
    connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
    connectStorageEmulator(storage, "127.0.0.1", 9199);
  }

  const appStyle = isLightTheme ? "App-main" : "App-main App-dark";

  if (status === "loading") {
    return <div />;
  } else if (user) {
    return (
      <FirestoreProvider sdk={firestore}>
        <StorageProvider sdk={storage}>
        <DocumentNameProvider>
          <div className={appStyle}>
            <Header updateTheme={(value) => updateTheme(value)}/>
            <Routes>
              <Route path="/generate" element={<Prompt />} />
              <Route path="/images" element={<Gallery uid={user.uid}/>} />
              <Route path="/images/:imageId" element={<AuthImage uid={user.uid}/>} />
              <Route path="/" element={<Navigate to="/generate" replace />} />
            </Routes>
          </div>
          </DocumentNameProvider>
        </StorageProvider>
      </FirestoreProvider>
    );
  }
  return <Login />;
}

export default AuthApp;
