import React from "react";

import Robot from './Robot2';
import './processing.css';

const Processing = () => {
  return (
    <div className="Processing">
      <div className="Processing-logo"><Robot animation='glow' /></div>

      <div className="Processing-text">
        Generating
      </div>
    </div>
  )
}

export default Processing;