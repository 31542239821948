import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import React, { useEffect, useState } from "react";
import useUpdateImage from './hooks/use-update-image.js';
import "./Details.css";

const Details = ({ doc }) => {

    // Extract details from the document
    const finished_at = doc?.finished_at
        ? doc.finished_at.toDate().toDateString()
        : "";
    const seed = doc?.seed ? doc.seed : "";
    const prompt = doc?.prompt ? doc.prompt : "";
    const uid = doc?.uid ? doc.uid : "";
    const image_id = doc?.image_id ? doc.image_id : "";
    const doc_id = `${uid}_${image_id}`;
    const imageName = doc?.image_id ? doc.name : "";

    // Hook for updating image document
    const updateImage = useUpdateImage(doc_id);

    const [name, setName] = useState(imageName);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setName(imageName)
    }, [imageName]);


    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleApply = () => {
        setIsEditing(false);
        updateImage({ name })
    };

    // Toggle edit mode
    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <div className="Details-main">
            <h2>Image details</h2>

            <div className="Details-row-edit">
                <strong>Name:</strong>
                {isEditing ? (
                    <Input
                        value={name}
                        onChange={handleNameChange}
                        endDecorator={
                            <IconButton variant="solid" onClick={handleApply}>
                                Apply
                            </IconButton>
                        }
                        autoFocus
                    />
                ) : (
                    <span onClick={toggleEdit} className="Details-editable">{name}</span> // Click to edit
                )}
            </div>
            <div className="Details-row">
                <strong>Created at:</strong>
                {finished_at}
            </div>
            <div className="Details-row">
                <strong>Prompt:</strong>
                {prompt}
            </div>
            <div className="Details-row">
                <strong>Seed:</strong>
                <div className="Details-seed">{seed.toString(32)}</div>
            </div>
        </div>
    );
};

export default Details;
