import React from "react";
import useImageUrl from './hooks/use-image-url';

import "./Thumbnail.css";

const Thumbnail = ({ url }) => {
  const { status, data: imageURL } = useImageUrl(url);
  const loading = status !== "success";

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Thumbnail-main">
      {imageURL && <img src={imageURL} className="Image-image" alt="Thumbnail" />}
    </div>
  );
};

export default Thumbnail;
