import React, { createContext, useContext, useState } from 'react';

const DocumentNameContext = createContext();

export function useDocumentName() {
  return useContext(DocumentNameContext);
}

export const DocumentNameProvider = ({ children }) => {
  const [documentName, setDocumentName] = useState('');

  return (
    <DocumentNameContext.Provider value={{ documentName, setDocumentName }}>
      {children}
    </DocumentNameContext.Provider>
  );
};