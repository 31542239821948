import React from "react";
import './Logo.css'

const Logo = () => {
  return (
    <div className="Logo-main">
      <svg
        width="100%"
        viewBox="0 0 24.485956 28.166508"
        version="1.1"
        id="svg868"
      >
        <defs>
          <linearGradient id="gradient" x1="50%" y1="0%" x2="50%" y2="100%" >
            <stop offset="0%" stopColor="#cf4671">
            </stop>
            <stop offset="100%" stopColor="#94306c">
            </stop>
          </linearGradient>
        </defs>
        <g id="layer1" transform="translate(-58.057218,-128.29689)">
          <g id="g3227">
            <path
              className="Logo-foreground"
              opacity="1"
              stroke="none"
              d="m 80.00414,150.79081 c -1.577213,2.45973 -3.729286,4.07609 -6.280986,5.21092 -1.638443,0.72868 -1.69137,0.71568 -2.443295,-0.92603 -0.125129,-0.27321 -0.206544,-0.56644 -0.291002,-0.80246 0.231921,-0.47084 0.643427,-0.54342 0.998379,-0.70118 6.130174,-2.72418 8.816033,-8.95341 6.486136,-15.04347 -2.203281,-5.75912 -8.689724,-8.72671 -14.81804,-6.59453 -1.483436,0.51614 -1.64511,-0.3009 -2.030616,-1.17122 -0.483272,-1.0911 0.501261,-1.17175 1.070372,-1.39329 7.347744,-2.86032 15.209665,0.1497 18.450181,7.24325 2.201577,4.8193 1.789071,9.59316 -1.141129,14.17801 z"
              id="path269"
              strokeWidth="0.264583"
            />
            <g
              id="g1162-7"
              transform="matrix(0.26458333,0,0,0.26458333,49.413111,55.711548)"
            >
              <circle
                className="Logo-foreground"
                strokeWidth="2.57764"
                id="path382-5"
                cx="68.90126"
                cy="328.46237"
                r="36.230618"
              />
              <circle
                className="Logo-background"
                strokeWidth="4.07433"
                id="path1135-3"
                cx="57.365311"
                cy="316.49088"
                r="8.4740839"
              />
              <circle
                className="Logo-background"
                strokeWidth="1.93351"
                id="path1135-6-5"
                cx="86.89711"
                cy="327.72388"
                r="4.0214491"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
export default Logo;
