import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import Drawer from '@mui/joy/Drawer';
import Logo from './Logo';
import './Menu.css'
import Theme from "./Theme";

const Menu = ({ isLightTheme, toggleTheme }) => {
    const [drawerOpen, toggleDrawer] = useState(false);
    return (
        <div className="Menu-main">
            <div className="Menu-button" onClick={() => toggleDrawer(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="100%" viewBox="0 0 50 50">
                    <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"></path>
                </svg>
            </div>
            <Drawer open={drawerOpen} onClose={() => toggleDrawer(false)} sx={{
                ".Menu-main": {
                    "background": isLightTheme ? "#ffffff" : "#21222c",
                    "color": isLightTheme ? "#21222c" : "#ffffff"
                }
            }}>
                <div className="Menu-main">
                    <div className="Menu-header">
                        <div className="Header-logo">
                            <Logo />
                            <div className="Header-logo-text">LeoAI</div>
                        </div>
                        <Theme isLightTheme={isLightTheme} toggleTheme={() => toggleTheme()} />
                    </div>
                    <div className="Menu-items" >
                        <Link to="/generate" style={{ "color": isLightTheme ? "#21222c" : "#ffffff"}} onClick={() => toggleDrawer(false)}>
                            Generate
                        </Link>
                        <Link to="/images" style={{ "color": isLightTheme ? "#21222c" : "#ffffff"}} onClick={() => toggleDrawer(false)}>
                            Images
                        </Link>
                    </div>
                </div>

            </Drawer>
        </div>
    );
};
export default Menu;
