import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useDocumentName } from "./context/DocumentContext";
import Logo from './Logo';
import Theme from './Theme';
import Menu from './Menu';
import './header.css';

const Header = ({ updateTheme }) => {
    const location = useLocation();
    const { pathname } = location;
    const { documentName } = useDocumentName();
    const [isLightTheme, setIsLightTheme] = useState(true);

    const toggleTheme = () => {
        const updated = !isLightTheme;
        setIsLightTheme(updated);
        updateTheme(updated)
    }
    const hasImageIdTest = /^\/images\/([^\/]+)$/;
    const hasImageId = hasImageIdTest.test(pathname)

    let generateStyle = "Header-link";
    let galleryStyle = "Header-link";
    let imageStyle = "Header-link Header-link-active";

    if (pathname === "/generate") {
        generateStyle = "Header-link Header-link-active";
    } else if (pathname === "/images") {
        galleryStyle = "Header-link Header-link-active";
    }

    return (
        <div className="Header-main">
            <div className="Header-logo">
                <Logo />
                <div className="Header-logo-text">LeoAI</div>
            </div>
            <div className="Header-path">
                <Link to="/generate">
                    <div className={generateStyle}>Generate</div>
                </Link>
                <div className="Header-link">/</div>
                <Link to="/images">
                    <div className={galleryStyle}>Images</div>
                </Link>
                {
                    hasImageId ? (
                        <React.Fragment>
                            <div className="Header-link">/</div>
                            <div className={imageStyle}>{documentName}</div>
                        </React.Fragment>
                    ) : null
                }
            </div>
            <div className="Header-theme">
                <div className="Header-menu">
                    <Menu isLightTheme={isLightTheme} toggleTheme={() => toggleTheme()} />
                </div>

                <div className="Header-switch">
                    <Theme isLightTheme={isLightTheme} toggleTheme={() => toggleTheme()} />
                </div>

            </div>

        </div>
    );
}

export default Header;
