import React from "react";
import useImageUrl from './hooks/use-image-url';
import "./Image.css";

const Image = ({ url }) => {

  const { status, data: imageURL } = useImageUrl(url);

  const loading = status !== "success";
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Image-main">
      {imageURL && (
        <img src={imageURL} className="Image-image" alt="Generated" />
      )}
    </div>
  );
};

export default Image;
