import React from "react";
import Switch from '@mui/joy/Switch';
import './Theme.css'

const Theme = ({ isLightTheme, toggleTheme }) => {
    const switchColor = isLightTheme ? "#e6e6e6" : "#636b74";
    return (
        <div className="Theme-main">
            <Switch
                color="neutral"
                checked={isLightTheme}
                onChange={() => toggleTheme()}
                slotProps={{
                    track: {
                        children: (
                            <React.Fragment>
                                <span className="Header-theme-switch-left">
                                    🌞
                                </span>
                                <span className="Header-theme-switch-right">
                                    🌜
                                </span>
                            </React.Fragment>
                        ),
                    },
                }}
                sx={{
                    '--Switch-thumbSize': '27px',
                    '--Switch-trackWidth': '54px',
                    '--Switch-trackHeight': '1.5rem',
                    '--Switch-trackBackground': switchColor,
                    '.Mui-checked': {
                        '--Switch-trackBackground': switchColor
                    }
                }}
            />
        </div>
    );
};
export default Theme;
